import React, {useState, useEffect} from 'react'
import './ShowReadingAssignment.css'
import {useParams, useNavigate} from 'react-router-dom'
import {auth, db} from '../../firebase'
import pencilIcon from '../pencil.svg'
import userIcon from '../user.svg'
import openIcon from './less-than.svg'
import SummaryCheck from './SummaryCheck/SummaryCheck'
const fetchReading = async (readingId, setReading) => {
  const readingRef = db.collection('readings').doc(readingId);
  try {
    const doc = await readingRef.get();
    if (doc.exists) {
      setReading(doc.data());
    } else {
      console.log('No such document!');
    }
  } catch (error) {
    console.log('Error getting document:', error);
  }
};
const addParagraphNumbers = (htmlContent) => {
  const domParser = new DOMParser();
  const doc = domParser.parseFromString(htmlContent, 'text/html');
  const insTags = doc.querySelectorAll('ins');
  let count = 1;
  for (const insTag of insTags) {
    const paragraph = insTag.closest('p');
    if (paragraph) {
      const number = document.createElement('span');
      number.classList.add('paragraph-number');
      number.textContent = `${count}. `;
      paragraph.insertBefore(number, insTag);
      count++;
    }
  }
  return doc.body.innerHTML;
};// This function is used to add paragraph numbers to the reading (taking in the html string input)
const ShowReadingAssignment = () => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [reading, setReading] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const {readingId} = useParams()
    const [showSummaryCheck, setShowSummaryCheck] = useState(false)
    const navigate = useNavigate()

    const toggleSummaryCheck = () => {
      setShowSummaryCheck(!showSummaryCheck)
    }


    const handleLogout = () => {
      auth.signOut().then(() => {
        navigate('/')
      })
    }

    useEffect(() => {
      fetchReading(readingId, setReading)
        .finally(() => setIsLoading(false)); // Set loading to false when data is fetched
    }, [readingId]);
  
    if (isLoading) { // Render loading state while data is being fetched
      return <div>Loading...</div>;
    }

  return (
    <div>
      <header>
        <h1>
        Linr.io / Reading
        <span className="blinking-cursor">|</span>
        </h1>
        <button className="todo-button" onClick={()=> navigate('/')}>
          To-do List
          <img src={pencilIcon} alt="Pencil Icon" className="pencil-icon"/>
        </button>
        <button 
          className="user-button" 
        >
          <img src={userIcon} alt="User Icon" className="user-icon" onClick={()=>setShowDropdown(!showDropdown)}/>
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="logout-button"  onClick={handleLogout}>Log Out</button>
            </div>
          )}
        </button>
        <button className="menu-button" onClick={toggleSummaryCheck}>
          <img src={openIcon} alt="Menu Icon" className="menu-icon" />
        </button>
      </header>
        <div className='show-reading-assignment'>
            <div className='reading-content'>
                <div className='reading-title'>
                <h2 dangerouslySetInnerHTML={{__html:reading.title}}/>
                </div>
                <div className='reading-body'>
                <div dangerouslySetInnerHTML={{ __html: addParagraphNumbers(reading.contents) }} />
                </div>
            </div>
            {!showSummaryCheck&&(<div className='instruction-content'>
              <h2>How to do it?</h2>
              <div className='instruction-content-box'>
                <br></br>
                <li>Underlined paragraphs are what the assigner want you to really focus on</li>
                <br></br>
                <li>So you will have to summarize all of them one by one</li>
                <br></br>
                <li>Good news! You only have to preserve the main content</li>
                <br></br>
                <li>Our AI will make sure that your content is valid</li>
                <br></br>
                <li>Now go ahead, hit the top-right button and study better with Linr!</li>
              </div>
            </div>)}
        
            {showSummaryCheck && (
              <div className='summary-check'>
                <SummaryCheck summaries={reading.summaries}></SummaryCheck>
              </div>)}
        </div>
    </div>
  )
}

export default ShowReadingAssignment