import React, { useState } from "react";
//import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./Landing.css";
import {signInWithGoogle} from '../../firebase'
import writingImage from './writing.jpg'
import googleImage from './google.svg'



const Landing = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const handleLoginClose = () => {
    setShowLogin(false);
  };

  const handleRegisterClick = () => {
    setShowRegister(true);
  };

  const handleRegisterClose = () => {
    setShowRegister(false);
  };
  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    signInWithGoogle()
    .then(async (userCredential) => {
      // Signed in
    }).catch((error) => {
      console.log(error.code);
      console.log(error.message);
    })
  }
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    signInWithGoogle()
    .then(async (userCredential) => {
      // Signed in
    }).catch((error) => {
      console.log(error.code);
      console.log(error.message);
    })
  }

  return (
    <div className={showLogin || showRegister ? "no-click" : ""}>
      <header>
        <h1>
          Linr.io
          <span className="blinking-cursor">|</span>
        </h1>
        <button className="login-button" onClick={handleLoginClick}>
          Log In
        </button>
        
        <button className="about-button" onClick={()=>{
            window.location.href = '/about'
        }}>
            About Us
        </button>
      </header>
      {showLogin && (
        <div className="login-popup">
          <div className="login-popup-content">
            <span className="login-popup-close" onClick={handleLoginClose}>
              &times;
            </span>
            <h2>Welcome back!</h2>
            <form onSubmit={handleLoginSubmit}>
            <button type="submit" className="sign-in-button"><img src={googleImage} alt="googleImage" className="googleImage"/> Google Sign In</button>
            </form>
          </div>
        </div>
      )}
      {showRegister && (
        <div className="register-popup">
          <div className="register-popup-content">
            <span className="register-popup-close" onClick={handleRegisterClose}>
              &times;
            </span>
            <h2>Join the Linr.io community!</h2>
            <form onSubmit={handleRegisterSubmit}>
              <button type="submit" className="sign-in-button"><img src={googleImage} alt="googleImage" className="googleImage"/> Google Sign In</button>
            </form>
          </div>
        </div>
      )}
      <div className="landing-body">
        <div className="thumb">
          <div className="thumb-p">
          <p className="thumb-h2">What is Linr.io?</p>
          <p>Share and discover great reads on our platform. Upload and connect with like-minded readers. Join now to expand your literary horizons!</p>
          </div>
          <div>
            <button className="register-button" onClick={handleRegisterClick}>
              Try it out for free &#8599;
            </button>
          </div>
        </div>
        <div className="thumb-pic">
          <img src={writingImage} className="writing-image" alt="writing"/>
        </div>
      </div>
    </div>
  );
};



export default Landing;