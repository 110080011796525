import React from 'react'
import { useState } from 'react';
import { auth, db } from '../../../firebase.js'
import { useAuthState } from 'react-firebase-hooks/auth'
import { CircularProgress } from '@mui/material';
import {Carousel} from 'primereact/carousel'
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import { useNavigate } from 'react-router-dom';
import './ShowPreview.css'



const ShowPreview = ({title, docContents, importantContents, summaries, setShowPreview}) => {
    const [user] = useAuthState(auth);
    const [finalSummaries, setFinalSummaries] = useState(summaries.map((summary, index) => [index, summary]));
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSummaryChange = (index, value) => {
        const newValues = [...finalSummaries];
        newValues[index] = [index,value];
        setFinalSummaries(newValues);
      };
    const summaryTemplate = (summary) => {
        return (
            <div className="summary-preview">
            <h3>Summary {summary[0]+1}:</h3>
            <textarea
                value={summary[1]}
                onChange={(e) => handleSummaryChange(summary[0], e.target.value)}
            />
            </div>
        );
        };

    const addParagraphNumbers = (htmlContent) => {
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(htmlContent, 'text/html');
        const insTags = doc.querySelectorAll('ins');
        let count = 1;
        for (const insTag of insTags) {
          const paragraph = insTag.closest('p');
          if (paragraph) {
            const number = document.createElement('span');
            number.classList.add('paragraph-number');
            number.textContent = `${count}. `;
            paragraph.insertBefore(number, insTag);
            count++;
          }
        }
        return doc.body.innerHTML;
      };// This function is used to add paragraph numbers to the reading (taking in the html string input)

    const handleCreateReading = async () => {
        // Save the new reading to Firestore
        try {
            setIsLoading(true);
            const newReading = await db.collection("readings").add({
              creatorUid: user.uid,
              title: title,
              contents: docContents,
              importantContents: importantContents,
              summaries: finalSummaries.map((summary) => summary[1]), //finalSummaries was a[[index, summary]] so we need to map it to just [summary]
              creatorName: user.displayName,
              participants: [],
            });
            //add to current user's class list
            const userRef = await db
              .collection("users")
              .where("uid", "==", user.uid)
              .get();
            const docId = userRef.docs[0].id;
            const userData = userRef.docs[0].data();
            let userCreatedReadings = userData.createdReadings;
            userCreatedReadings.push({
              id: newReading.id,
              title: title,
            });
            const docRef = await db.collection("users").doc(docId);
            await docRef.update({
              createdReadings: userCreatedReadings,
            });
            navigate('/')
            alert("Reading created successfully!");
          } catch (err) {
            console.log(err);
            alert(`Cannot create reading, try again later - ${err.response.data.message}`);
            setIsLoading(false);
          }
      };
  return (
    <div className='preview'>
      <div className='preview-reading-content'>
            <div className='preview-reading-title'>
            <h2 dangerouslySetInnerHTML={{__html:title}}/>
            </div>
            <div className='preview-reading-body'>
            <div dangerouslySetInnerHTML={{ __html: addParagraphNumbers(docContents) }} />
            </div>
     </div>
      <div className='preview-summaries'>
        <div className='card flex justify-content-center'>
            <Carousel value={finalSummaries} 
            numVisible={1} 
            numScroll={1} 
            itemTemplate={summaryTemplate} 
            orientation='vertical'
            verticalViewPortHeight="360px"/>
        </div>
        <div className='preview-summaries-buttons'>
                {isLoading ? (
          <CircularProgress color='inherit'></CircularProgress>
            ) : (
              <>
                <button onClick={handleCreateReading} className='preview-summaries-button'>Create Reading</button>
                <button onClick={()=> setShowPreview(false)} className='preview-summaries-button'>Cancel</button>
              </>
            )}
        </div>
     </div>
    </div>
  )
}



export default ShowPreview
