// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYRfg1dHjLfzTiufQqoK7vXN-TsCOk0XQ",
  authDomain: "linr-46db0.firebaseapp.com",
  databaseURL: "https://linr-46db0-default-rtdb.firebaseio.com",
  projectId: "linr-46db0",
  storageBucket: "linr-46db0.appspot.com",
  messagingSenderId: "684265523313",
  appId: "1:684265523313:web:d0844de5e87ccf1065b3d0",
  measurementId: "G-R962EVHXT3"
};
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();
// Sign in and check or create account in firestore
const signInWithGoogle = async () => {
  try {
    const response = await auth.signInWithPopup(googleProvider);
    const user = response.user;
    const querySnapshot = await db
      .collection("users")
      .where("uid", "==", user.uid)
      .get();
    if (querySnapshot.docs.length === 0) {
      // create a new user
      await db.collection("users").add({
        uid: user.uid,
        assignedReadings: [],
        createdReadings: [],
      });
    }
    return user.uid;
  } catch (err) {
    alert(err.message);
  }
};
const logout = () => {
  auth.signOut();
};

export { app, auth, db, signInWithGoogle, logout };