
import React, { useState } from 'react'
import { auth, db } from '../../../firebase.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { CircularProgress } from '@mui/material';
import './JoinReading.css'

const JoinReading = ({onClose}) => {
    const [user] = useAuthState(auth);
    const [accessCode, setAccessCode] = useState('')
    const [loading, setLoading] = useState(false)

    const handleCodeChange = (e) => {
        setAccessCode(e.target.value)
    }

    const handleClear = () => {
        setAccessCode('')
    }
    const join = async () => {
        setLoading(true);
        try {
          // check if class exists
          const readingRef = await db.collection("readings").doc(accessCode).get();
          if (!readingRef.exists) {
            return alert(`Reading doesn't exist, please provide correct ID`);
          }
          const readingData = await readingRef.data();
          // add reading to user's assignedReadings
          const userRef = await db.collection("users").where("uid", "==", user.uid);
          const userData = await (await userRef.get()).docs[0].data();
          let tempReadings = userData.assignedReadings;
          if (tempReadings.find((reading) => reading.id === accessCode)) {
            onClose()
            return alert(`You are already enrolled in ${readingData.title}`);
          }
          tempReadings.push({
            id: accessCode,
            title: readingData.title,
            progress: 0,
            toDo: readingData.importantContents.length,
            finished: false,
          });
          await (
            await userRef.get()
          ).docs[0].ref.update({
            assignedReadings: tempReadings,
          });
          // add user to the reading's participants
          const readingParticipants = readingData.participants;
          readingParticipants.push({
            uid: user.uid,
            name: user.displayName,
            // progress: 0,
            // toDo: readingData.importantContents.length,
            finished: false,
          });
          await readingRef.ref.update({
            participants: readingParticipants,
          });
          // alert done
          alert(`Enrolled in ${readingData.title} successfully!`);
          onClose()
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
        setLoading(false);
      };

    return (
        <div className='join-reading'>
            <h2>Join Reading</h2>
            <div className='code-input'>
                <input type="text" value={accessCode} onChange={handleCodeChange} placeholder='Enter reading access code:'/>
                <button onClick={handleClear} className='clear-button'>Clear</button>
            </div>
            <div className='button-container'>
              {loading?<CircularProgress color='inherit' size={20} thickness={5}/>:
              <>
                <button onClick={join} className='join-reading-button'>Join A Reading</button>
                <button onClick={onClose} className='join-reading-button'>Cancel</button>
              </>}
            </div>
        </div>
    )
}

export default JoinReading