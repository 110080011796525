import React, { useState, useEffect, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { axios } from '../../axios';
import './ShowDoc.css';
import TextEditor from '../TextEditor/TextEditor';
import {  EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import JSZip from 'jszip'; 
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const ShowDoc = ({handleContentsChange}) => {
  const [docTitle, setDocTitle] = useState(''); // this is the title of the document
  const [customTitle, setCustomTitle] = useState(''); // this is the custom title of the document
  const [docContents, setDocContents] = useState(null); // this is the contents of the document (html)
  const [importantContents, setImportantContents] = useState([]); // this is the important contents of the document (html) - contents that the user wants to highlight
  // that 3 will be passed to the create reading component
  const [uploaded, setUploaded] = useState(false);
  const [articleUrl, setArticleUrl] = useState('');
  const [inputMode, setInputMode] = useState('file');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    handleContentsChange(docTitle,docContents, importantContents);
  }, [docContents, importantContents, docTitle, handleContentsChange]);

  

  const handleCustomTitleChange = (event) => {
    setCustomTitle(event.target.value);
  };

  useEffect(() => {
    if (inputMode === 'custom') {
      setDocTitle(customTitle);
    }
  }, [customTitle, inputMode]);

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(
      rawContentState
    );
    setDocContents(markup);
    const insMatches = markup.match(/<ins>(.*?)<\/ins>/g);
    if(!insMatches){
      setImportantContents([]);
      return;
    }
    const insTextArray = insMatches.map(match => match.replace(/<\/?ins>/g, ''));
    // this function is used to handle the save changes of the editor, by taking in the part that is underlined
    // and save it to the database
    setImportantContents(insTextArray);
  }, [editorState]);
//This useEffect is used to pass the contents to the create reading component


  const htmlToEditorState = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
   }// this function is used to convert the html to editor state


// Extension MIME Type
// .doc      application/msword
// .dot      application/msword

// .docx     application/vnd.openxmlformats-officedocument.wordprocessingml.document
// .dotx     application/vnd.openxmlformats-officedocument.wordprocessingml.template
// .docm     application/vnd.ms-word.document.macroEnabled.12
// .dotm     application/vnd.ms-word.template.macroEnabled.12

// .xls      application/vnd.ms-excel
// .xlt      application/vnd.ms-excel
// .xla      application/vnd.ms-excel

// .xlsx     application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
// .xltx     application/vnd.openxmlformats-officedocument.spreadsheetml.template
// .xlsm     application/vnd.ms-excel.sheet.macroEnabled.12
// .xltm     application/vnd.ms-excel.template.macroEnabled.12
// .xlam     application/vnd.ms-excel.addin.macroEnabled.12
// .xlsb     application/vnd.ms-excel.sheet.binary.macroEnabled.12

// .ppt      application/vnd.ms-powerpoint
// .pot      application/vnd.ms-powerpoint
// .pps      application/vnd.ms-powerpoint
// .ppa      application/vnd.ms-powerpoint

// .pptx     application/vnd.openxmlformats-officedocument.presentationml.presentation
// .potx     application/vnd.openxmlformats-officedocument.presentationml.template
// .ppsx     application/vnd.openxmlformats-officedocument.presentationml.slideshow
// .ppam     application/vnd.ms-powerpoint.addin.macroEnabled.12
// .pptm     application/vnd.ms-powerpoint.presentation.macroEnabled.12
// .potm     application/vnd.ms-powerpoint.template.macroEnabled.12
// .ppsm     application/vnd.ms-powerpoint.slideshow.macroEnabled.12

// .mdb      application/vnd.ms-access

  const getFileExtension = (file) => {
    return file.type.split('/').pop();
  };
  
  const handleTextFile = (file, callback) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const paragraphs = fileReader.result
        .split('\n')
        .map((p) => `<p>${p}</p>`)
        .join('');
      callback(paragraphs, file.name);
    };
    fileReader.readAsText(file);
  };
  
  const handleDocxFile = (file, callback) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const buffer = fileReader.result;
      const zip = new JSZip();
      zip.loadAsync(buffer).then((data) => {
        const xmlData = data.files['word/document.xml'];
        if (xmlData) {
          xmlData.async('text').then((text) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(text, 'text/xml');
            const paragraphs = xmlDoc.getElementsByTagName('w:p');
            let content = '';
            for (let i = 0; i < paragraphs.length; i++) {
              const paragraph = paragraphs[i];
              const runs = paragraph.getElementsByTagName('w:r');
              let paragraphContent = '';
              for (let j = 0; j < runs.length; j++) {
                const run = runs[j];
                const texts = run.getElementsByTagName('w:t');
                for (let k = 0; k < texts.length; k++) {
                  const textNode = texts[k];
                  paragraphContent += textNode.textContent;
                }
              }
              content += `<p>${paragraphContent}</p>`;
            }
            callback(content, file.name);
          });
        }
      });
    };
    fileReader.readAsArrayBuffer(file);
  };
  // const handleDocFile = (file, callback) => {
  //   const fileReader = new FileReader();
  //   fileReader.onload = () => {
  //     const buffer = fileReader.result;
  //     console.log(buffer);
  //   };
  //   fileReader.readAsText(file);
  // }

  const handlePdfFile = (file, callback) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const arrayBuffer = fileReader.result;
      const pdf = await pdfjs.getDocument(arrayBuffer).promise;
      const pageCount = pdf.numPages;
      let content = '';
      for (let pageNumber = 1; pageNumber <= pageCount; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const textContent = await page.getTextContent();
        const textItems = textContent.items;
        const pageText = textItems.map(item => item.str).join(' ');
        content += `<p>${pageText}</p>`;
      }
      callback(content, file.name);
    };
    fileReader.readAsArrayBuffer(file);
  };


  
  const handleDrop = (acceptedFiles) => {
    if (!acceptedFiles) return;
  
    const file = acceptedFiles[0];
    const fileExtension = getFileExtension(file);
    const docxType = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
    const pdfType = 'pdf';
    if (fileExtension === '') {
      console.log('File has no MIME type');
      return;
    }
  
    const updateState = (content, fileName) => {
      setDocContents(content);
      setEditorState(htmlToEditorState(content));
      setUploaded(true);
      setIsEditing(true);
      setDocTitle(fileName.split('.')[0]);
    };
  
    if (fileExtension === 'txt') {
      handleTextFile(file, updateState);
    } else if (fileExtension === docxType) {
      handleDocxFile(file, updateState);
    } else if (fileExtension === pdfType) {
      // to be implemented
      handlePdfFile(file, updateState);
    } else {
      window.alert('Unsupported file type');
    }
  };


  const handleInputChange = (event) => {
    setArticleUrl(event.target.value);
  };

  const handleSelectChange = (event) => {
    setInputMode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      new URL(articleUrl);
      await axios.post('/get-article', { url: articleUrl }).then((res) => {
        const title = res.data.message.title;
        const paragraphs = res.data.message.paragraphs.map((p) => `<p>${p}</p>`).join('')
        setDocTitle(title);
        setDocContents(paragraphs);
        setEditorState(htmlToEditorState(paragraphs));
        setUploaded(true);
        setIsEditing(true);
      }).catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log('Invalid URL:', articleUrl);
    }
  };// this function is used to handle the submit of the url
  const handleEditorChange = useCallback((editorState) => {
    setEditorState(editorState);
  }, []);
  
  return (
    <div>
      {(!uploaded &&<label>
        <span>Input mode:</span>
        <select value={inputMode} onChange={handleSelectChange} className='input-options'>
          <option value="file">File</option>
          <option value="url">Article URL</option>
          <option value="custom">Custom</option>
        </select>
      </label>)}
      {(!uploaded && inputMode === 'file') && (
        <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop a .txt, .pdf, or .docx file here, or click to select a file</p>
            </div>
          </section>
        )}
        </Dropzone>
      )}
      {(!uploaded && inputMode === 'url') && (
        <form onSubmit={handleSubmit}>
          <label>
            <br></br>
              <div className='url-input'>
                <div className='url-input-box'>
                <input className='url-input-text-area' type="text" value={articleUrl} onChange={handleInputChange} placeholder='Article url'/>
              </div>
            </div>
          </label>
          <button type="submit" className='show-sample-button'>Show Sample</button>
        </form>
      )}
      {docContents && isEditing && (
        <div>
        <h2 dangerouslySetInnerHTML={{__html:"Title: "+docTitle}}/>
        <TextEditor editorState = {editorState} handleEditorChange={handleEditorChange}></TextEditor>
        </div>
      )}
      {(!uploaded && inputMode === 'custom') && (
        <div>
          <label>
          <p></p>
            Title:
            <p></p>
            <input type="text" value={customTitle} onChange={handleCustomTitleChange} className='custom-title-box'/>
          </label>
          <TextEditor editorState={editorState} handleEditorChange={handleEditorChange}></TextEditor>
        </div>
      )}
      
    </div>
  );
};

export default ShowDoc;