import React from 'react';
import './ReadingListingCard.css';
import classroomIcon from './classroom.svg'
import settingIcon from './settings.svg'
import {useNavigate} from 'react-router-dom'

//key={reading.id} reading={reading} setModalType={setModalType} setReadingToDelete={setReadingToDelete}
const ReadingListingCard = ({ reading, setModalType, setReadingToDelete,setReadingToAnalytics }) => {
  const navigate = useNavigate()
  const handleRoutingToEditReading = () => {
    navigate(`/edit-reading/${reading.id}`)
  }
  return (
    <div className="reading-listing-card">
      <div className="reading-title-box">
        <h2 dangerouslySetInnerHTML={{ __html: reading.title }}></h2>
        <button className='delete-button' onClick={()=>{
          setModalType('DeleteReading')
          setReadingToDelete(reading.id)
          }}>&#10005;</button> Access code: {reading.id} 
      </div>
      <div className='reading-management-field'>
        <a className='icon-container'><img src={classroomIcon} 
        alt='ClassroomIcon' 
        className='classroom-icon'
        onClick={()=>{
          setModalType('ReadingAnalytics')
          setReadingToAnalytics(reading.id)
        }}
        /></a>
        <a className='icon-container'><img src={settingIcon} 
        alt='SettingIcon' 
        className='settings-icon' 
        onClick={handleRoutingToEditReading}/></a>
      </div>
    </div>
  );
};

export default ReadingListingCard