import React from 'react';
import Landing from './components/Landing/Landing';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutUs from './AboutUs';
import Home from './components/HomePages/Home';
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from './firebase'
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import ShowReadingAssignment from './components/ShowReadingAssigment/ShowReadingAssignment';
import CreateReading from './components/CreateReading/CreateReading';
import EditReading from './components/EditReading/EditReading';


const App = () => {
  const [user,load] = useAuthState(auth);
  if(load){
    return <Stack sx={{ color: 'grey.500' , display: 'flex', flexDirection:'row', justifyContent:'center'}} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Stack>
  }
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/' element={!user?<Landing/>:<Home />} />
          <Route exact path='/about' element={<AboutUs/>} />
          <Route exact path='/readings/:readingId' element={!user?<Landing/>:<ShowReadingAssignment/>}/>
          <Route exact path='/create-reading' element={!user?<Landing/>:<CreateReading/>}/>
          <Route exact path='/edit-reading/:readingId' element={!user?<Landing/>:<EditReading/>}/>
          <Route exact path='*' element={<h1>404: Not Found</h1>} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;