import React, {useEffect, useState} from 'react'
import './SummaryCheck.css'
import { CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from '../../../firebase'
import {axios} from '../../../axios'
import Loader from './Loader'




const SummaryCheck = ({summaries}) => {
  const [user] = useAuthState(auth);
  const [summary, setSummary] = useState('') // the summary in the text area
  const [progress, setProgress] = useState(0)
  const [toDo, setToDo] = useState(0)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState("")
  const { readingId } = useParams()
  const navigate = useNavigate()
  const handleSummaryChange = (event) => {
    setSummary(event.target.value)
  }
  const fetchUserProgress = async () => {
    try {
      const userRef = await db.collection('users').where('uid', '==', user.uid).limit(1).get();
      const userDoc = userRef.docs[0];
      const currentReadingProgress = userDoc.data().assignedReadings.find(reading => reading.id === readingId)
      setProgress(currentReadingProgress.progress)
      setToDo(currentReadingProgress.toDo)
    } catch (error) {
      console.log('Error getting document:', error);
    }
  }
  useEffect(() => {
    fetchUserProgress()
  })

  const updateUserProgress = async () => {
    try {
      const userRef = await db.collection('users').where('uid', '==', user.uid).limit(1).get();
      const userDoc = userRef.docs[0];
      const assignedReadings = userDoc.data().assignedReadings//.find(reading => reading.id === readingId)
      const currentReadingIndex = assignedReadings.findIndex(reading => reading.id === readingId)
      //This is the reading that the user is currently working on
      assignedReadings[currentReadingIndex].progress = progress + 1
      await db.collection('users').doc(userDoc.id).update({assignedReadings:assignedReadings})
      .then(() => {
        console.log('Document successfully updated!');
      })
      setProgress(progress + 1)
    } catch (error) {
      console.log('Error getting document:', error);
    }
  }
  const updateFinishedReading = async () => {
    try {
      const userRef = await db.collection('users').where('uid', '==', user.uid).limit(1).get();
      const userDoc = userRef.docs[0];
      const assignedReadings = userDoc.data().assignedReadings
      const currentReadingIndex = assignedReadings.findIndex(reading => reading.id === readingId)
      //This is the reading that the user is currently working on, we need to remove it from the assignedReadings array
      assignedReadings.splice(currentReadingIndex, 1)
      await db.collection('users').doc(userDoc.id).update({assignedReadings:assignedReadings})
      //Now we need to need to change the finished to true on the reading document
      const readingRef = await db.collection("readings").doc(readingId).get()
      const readingDoc = readingRef.data()
      //gotta find the correct object first
      const userFinishedReadingIndex = readingDoc.participants.findIndex(usr => usr.uid === user.uid)
      readingDoc.participants[userFinishedReadingIndex].finished = true
      await db.collection('readings').doc(readingId).update(readingDoc)
    } catch (error) {
      console.log('Error getting document:', error);
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await axios.post('/summary-check', {text1:summary,text2:summaries[progress]})
      .then(res => {
        //res.data looks like this {message:true/false, score:0-1}
        const pass = res.data.message
        const score = res.data.score
        if (pass) {
          const percentage = (score * 100).toFixed(2)
          if (progress + 1 === toDo) {
            updateFinishedReading()
            window.alert(`You finished the reading!`)
            navigate('/')
            return
          }
          updateUserProgress()
          setSummary('')
          setAlert(`Your passed! Your score is ${percentage}%`)
        } else{
          const percentage = (score * 100).toFixed(2)
          setAlert(`Your summary failed! Your score is ${percentage}%`)
        }
      })
    } catch (error) {
      window.alert(error.message)
    }
    setLoading(false)
  }
  return (
    <div className='summary-check-content'>
        <h2>Enter your summary:</h2>
        <div>
            <textarea 
            name='summary'
            value={summary}
            className='summary-check-textarea' 
            placeholder='Enter your summary here...'
            onChange={handleSummaryChange}
            rows={5}
            />
        </div>
        <p>Current paragraph:{progress+1}</p>
        <p>Total paragraphs:{toDo}</p>
        <div className='submit-button-container'>
          {loading?<CircularProgress color='inherit' size={40}/>:
          <button onClick={handleSubmit} className='submit-summary-button'>Submit</button>}
        </div>
        {loading?
        <div className='response-box-loading'>
            <Loader/>
        </div>:
        (alert.length===0?<></>:
        <div className='response-box'>
            {alert}
        </div>)
        }
    </div>
  )
}

export default SummaryCheck
