import React, { useEffect } from 'react'
import './ReadingAnalytics.css'
import { useState } from 'react'
import { db } from '../../../firebase'
import CircularProgressWithLabel from '../ReadingAnalytics/CircularProgressWithLabel/CircularProgressWithLabel.tsx'


const ReadingAnalytics = ({setModalType, readingToAnalytics}) => {
    const [selectedTab, setSelectedTab] = useState('Summary')
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
      };
    const [joinedUsers, setJoinedUsers] = useState([])//this one will be changed to the list of users who joined the reading
    const [progress, setProgress] = useState(0)//this one will be changed to the progress of the reading
    const fetchJoinedUsers = async () => {
        try {
            const readingRef = await db.collection("readings").doc(readingToAnalytics).get();
            if (!readingRef.exists) {
                return alert(`Reading doesn't exist, please provide correct ID`);
            }
            const readingData = await readingRef.data();
            const joinedUsers = readingData.participants.map((participant) => participant.name)
            setJoinedUsers(joinedUsers)
            console.log(joinedUsers)
        } catch (error) {
            console.error(error.message);
        }
    }
    const fetchProgress = async () => {
        try {
            const readingRef = await db.collection("readings").doc(readingToAnalytics).get();
            if (!readingRef.exists) {
                return alert(`Reading doesn't exist, please provide correct ID`);
            }
            const readingData = await readingRef.data();
            const allJoinedUsers = readingData.participants.length
            const finishedUsers = readingData.participants.filter((participant) => participant.finished).length
            const progress = (finishedUsers/allJoinedUsers)*100
            // round the progress to the nearest integer
            setProgress(Math.round(progress))
        } catch (error) {
            console.error(error.message);
        }
    }
    useEffect(() => {
        if(selectedTab === 'Participants'){
            fetchJoinedUsers()
        }
        if(selectedTab === 'Summary'){
            fetchProgress()
        }
    }, [selectedTab])

  return (
    <div className='reading-analytics-popup'>
        <div className='reading-analytics-popup-content'>
        <span className="reading-analytics-popup-close" onClick={()=>setModalType(null)}>
            &times;
        </span>
        <div className='reading-analytics-navbar'>
            <button className={`reading-analytics-button ${selectedTab === 'Summary' ? 'selected' : ''}`}
            onClick={() => handleTabClick('Summary')}>Summary</button>
            <button className={`reading-analytics-button ${selectedTab === 'Participants' ? 'selected' : ''}`}
            onClick={() => handleTabClick('Participants')}>Participants</button>
        </div>
        {selectedTab === 'Summary' && (
        <div className='reading-analytics-summary-progress'>
            <div>
                <CircularProgressWithLabel color='inherit' value={progress} size={120} />
            </div>
            <div className='reading-analytics-summary-progress-text'>
                <p>Reading Completion</p>
            </div>
        </div>)}
        {selectedTab === 'Participants' && (
            <div className='reading-analytics-participants-list'>
                {joinedUsers.map((user) => (
                    <div className='reading-analytics-participants-list-item'>
                        <h2 dangerouslySetInnerHTML={{ __html: user}}></h2>
                    </div>
                ))}
            </div>
        )}
        </div>
    </div>
  )
}

export default ReadingAnalytics
