import React, { useState } from 'react';
import './CreateReading.css';
import ShowDoc from '../ShowDoc/ShowDoc';
import ShowPreview from './ShowPreview/ShowPreview';
import { LinearProgress } from '@mui/material';
import { Box } from '@mui/material';
import {axios} from '../../axios.js'
import { useNavigate } from 'react-router-dom';

const CreateReading = () => {
  const [title, setTitle] = useState('');
  const [docContents, setDocContents] = useState(null);
  const [importantContents, setImportantContents] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();

  const handleContentsChange = (title,contents, importantContents) => {
    setTitle(title);
    setDocContents(contents);
    setImportantContents(importantContents);
  
    };


  const handlePreviewSummaries = async () => {
    // Send the important contents to the backend and get the summaries
    setIsLoading(true);
    try {
      if(!title || !docContents || !importantContents){
        window.alert('Please fill in all the fields');
      }
      const preSummaries = (await axios.post('/get-summaries', {importantContents})).data.message
      setSummaries(preSummaries);
      setIsLoading(false);
      setShowPreview(true);
    } catch (err) {
      console.log(err);
      alert(`Cannot create reading, try again later - ${err.response.data.message}`);
    }
    setIsLoading(false);
  };

  return (
    <div>
        <header>
          <h1>
          Linr.io / Create Reading Preview
          <span className="blinking-cursor">|</span>
          </h1>
        </header>
      <div className="create-reading">
        
        {!showPreview && (
            <div>
              <ShowDoc handleContentsChange={handleContentsChange} />
              {isLoading ? (
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              ) : (
                <div className="button-container">
                  <button onClick={handlePreviewSummaries} className='create-reading-button'>Preview Summaries</button>
                  <button onClick={() => {navigate('/')}} className='create-reading-button'>Cancel</button>
                </div>
              )}
            </div>
            )}

        {showPreview && <ShowPreview title={title} docContents={docContents} importantContents={importantContents} summaries={summaries} setShowPreview={setShowPreview}/>}

      </div>
    </div>
    
  );
};

export default CreateReading;