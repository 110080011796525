import React from 'react'
import { useState,useEffect } from 'react';
import { auth, db } from '../../firebase.js'
import { useAuthState } from 'react-firebase-hooks/auth'
import { CircularProgress } from '@mui/material';
import {Carousel} from 'primereact/carousel'
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import { useNavigate, useParams } from 'react-router-dom';
import './EditReading.css'

const fetchReading = async (readingId, setReading) => {
    const readingRef = db.collection('readings').doc(readingId);
    try {
      const doc = await readingRef.get();
      if (doc.exists) {
        setReading(doc.data());
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.log('Error getting document:', error);
    }
  };
  const addParagraphNumbers = (htmlContent) => {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(htmlContent, 'text/html');
    const insTags = doc.querySelectorAll('ins');
    let count = 1;
    for (const insTag of insTags) {
      const paragraph = insTag.closest('p');
      if (paragraph) {
        const number = document.createElement('span');
        number.classList.add('paragraph-number');
        number.textContent = `${count}. `;
        paragraph.insertBefore(number, insTag);
        count++;
      }
    }
    return doc.body.innerHTML;
  };


const EditReading = () => {
    const [user] = useAuthState(auth);
    const {readingId} = useParams();
    const [reading, setReading] = useState(null);
    // type reading = {
    //   contents: string;
    //   creatorName: string;
    //   creatorUid: string;
    //   importantContents: [string];
    //   summaries: [string];
    //   title: string;
    // };
    const [finalSummaries, setFinalSummaries] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    useEffect( () => {
        const checkIfReadingIsAssigned = async (readingId) => {
            try {
                const userDoc = await db.collection('users').where('uid', '==', user.uid).get()
                const createdReadings = userDoc.docs[0].data().createdReadings;
                const readingExists = createdReadings.some((reading) => reading.id === readingId);
                if (readingExists) {
                    return true
                } else {
                    return false
                }
            } catch (error) {
                console.error(error.message);
            }
        }
        const securedReading = checkIfReadingIsAssigned(readingId);
        if (!securedReading) {
            navigate('/');
            return;
        }
        fetchReading(readingId, setReading);
        setLoading(false);
    },[]);
    useEffect(() => {
        if (reading) {
            setFinalSummaries(reading.summaries.map((summary, index) => [index, summary]));
        }
    },[reading]);
    const handleSummaryChange = (index, value) => {
      const newValues = [...finalSummaries];
      newValues[index] = [index,value];
      setFinalSummaries(newValues);
    };
    const summaryTemplate = (summary) => {
        return (
            <div className="summary-preview-edit-reading">
            <h3>Summary {summary[0]+1}:</h3>
            <textarea
                value={summary[1]}
                onChange={(e) => handleSummaryChange(summary[0], e.target.value)}
            />
            </div>
        );
    };
    const handleUpdateReading = async () => {
        // Save the new reading to Firestore
        setLoading(true);
        try {
            const readingRef = db.collection('readings').doc(readingId);
            await readingRef.update({
                summaries: finalSummaries.map((summary) => summary[1]),
            });
            navigate('/');
        } catch (error) {
            console.error(error.message);
        }
        setLoading(false);
    };
    
  return (
    <div>
        <header>
            <h1>
            Linr.io / Edit Reading Summary
            <span className="blinking-cursor">|</span>
            </h1>
        </header>
        {reading&& finalSummaries &&
        (<div className='preview-edit-reading'>
            <div className='preview-edit-reading-content'>
                <div className='preview-edit-reading-title'>
                  <h2 dangerouslySetInnerHTML={{__html:reading.title}}/>
                </div>
                <div className='preview-edit-reading-body'>
                  <div dangerouslySetInnerHTML={{ __html: addParagraphNumbers(reading.contents) }} />
                </div>
            </div>
            <div className='preview-edit-reading-summaries'>
              <div className='card flex justify-content-center'>
                  <Carousel value={finalSummaries} 
                  numVisible={1} 
                  numScroll={1} 
                  itemTemplate={summaryTemplate} 
                  orientation='vertical'
                  verticalViewPortHeight="360px"/>
              </div>
              <div className='preview-edit-reading-summaries-buttons'>
                {loading?(
                <CircularProgress color='inherit'></CircularProgress>
                  ):(<>
                    <button onClick={() => navigate('/')} className='preview-summaries-button'>Cancel</button>
                    <button onClick={handleUpdateReading} className='preview-summaries-button'>Update</button>
                  </>)}
              </div>
            </div>
        </div>)
        }
    </div>
  )
}

export default EditReading
