import React, { useEffect, useState } from 'react'
import './Home.css'
import pencilIcon from '../pencil.svg'
import userIcon from '../user.svg'
import menuIcon from '../icon_menu.svg'
import { auth, db } from '../../firebase.js'
import { useAuthState } from 'react-firebase-hooks/auth'
import ReadingListingCard from './ReadingListingCard/ReadingListingCard'
import JoinReading from './JoinReading/JoinReading'
import JoinedReadingListingCard from './JoinedReadingListingCard copy/JoinedReadingListingCard'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import ReadingAnalytics from './ReadingAnalytics/ReadingAnalytics'


const Home = () => {
  const [user] = useAuthState(auth)
  const [createdReadings, setCreatedReadings] = useState([])
  const [assignedReadings, setAssignedReadings] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [readingToDelete, setReadingToDelete] = useState(null)
  const [readingToAnalytics, setReadingToAnalytics] = useState(null)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const navigate = useNavigate()

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/')
    })
  }

  const openModal = (type) => {
    if (modalType === type) {
      setModalType(null)
    } else {
      setModalType(type)
    }
  }

  useEffect(() => {
    const fetchReadings = async () => {
      try {
        await db
          .collection("users")
          .where("uid", "==", user.uid)
          .onSnapshot((snapshot) => {
            setCreatedReadings(snapshot?.docs[0]?.data()?.createdReadings);
            setAssignedReadings(snapshot?.docs[0]?.data()?.assignedReadings);
          });
      } catch (error) {
        console.error(error.message);
      }
    }
    fetchReadings();
  },[user.uid]);

  const deleteReading = async (readingId) => {
    setLoadingDelete(true)
    try {
      const users = await db.collection('users').get();
      users.forEach(async (user) => {
        const userReadings = user.data().assignedReadings;
        const newReadings = userReadings.filter((reading) => reading.id !== readingId);
        await db.collection('users').doc(user.id).update({ assignedReadings: newReadings });
      })
      //delete the reading from the readings collection
      await db.collection('readings').doc(readingId).delete();
      //delete the reading from the current user createdReadings array
      await db.collection('users').where('uid', '==', user.uid).get().then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const userReadings = doc.data().createdReadings;
          const newReadings = userReadings.filter((reading) => reading.id !== readingId);
          await db.collection('users').doc(doc.id).update({ createdReadings: newReadings });
        })
      }
      )
      setModalType(null)

    } catch (error) {
      console.error(error.message);
    }
    setLoadingDelete(false)
  };
  return (
    <div>
      <header>
        <h1>
        Linr.io / Task Manager
        <span className="blinking-cursor">|</span>
        </h1>
        <button className="todo-button">
          To-do List
          <img src={pencilIcon} alt="Pencil Icon" className="pencil-icon"/>
        </button>
        <button 
          className="user-button" 
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <img src={userIcon} alt="User Icon" className="user-icon"/>
          {showDropdown && (
            <div className="dropdown-menu">
              <button className="logout-button" onClick={handleLogout}>Log Out</button>
            </div>
          )}
        </button>
        <button className="menu-button" onClick={() => setShowSidebar(!showSidebar)}>
          <img src={menuIcon} alt="Menu Icon" className="menu-icon" />
        </button>
      </header>
      {showSidebar && (
        <div className="sidebar">
          <button className="sidebar-button" onClick={() => openModal('join')}>
            Join Reading
          </button>
          <button className="sidebar-button" onClick={() => navigate('/create-reading')}>
            Create Reading
          </button>
        </div>
      )}
      {modalType === 'join' && (
        <div className="create-reading-modal">
          <JoinReading onClose={() => setModalType(null)} />
        </div>
      )}
      {modalType === 'DeleteReading' && (
         <div className="delete-reading-popup">
         <div className="delete-reading-popup-content">
           <span className="delete-reading-popup-close" onClick={()=>setModalType(null)}>
             &times;
           </span>
           <h3>This will delete reading permanently!</h3>
             {loadingDelete?<CircularProgress color='inherit' size={20}/>:
             <button type="submit" className='delete-reading-button' onClick={()=>deleteReading(readingToDelete)}>Delete</button>}
         </div>
       </div>
        )
      }
      {modalType === 'ReadingAnalytics' && 
        <ReadingAnalytics setModalType={setModalType} readingToAnalytics={readingToAnalytics}/>
        }

      <div className='home-body'>
          <div className='toDoList'>
            <div className='created-readings'>
              <h2>Created Readings</h2>
              <div className="created-readings-box">
                {createdReadings.length > 0 ? (
                  createdReadings.map((reading) => (
                    <ReadingListingCard key={reading.id} 
                    reading={reading} 
                    setModalType={setModalType} 
                    setReadingToDelete={setReadingToDelete}
                    setReadingToAnalytics={setReadingToAnalytics}/>
                  ))
                ) : (
                  <p>No created readings yet.</p>
                )}
              </div>
            </div>
            <div className='joined-readings'>
                <h2>To-Do Readings</h2>
                <div className="joined-readings-box">
                  {assignedReadings.length > 0 ? (
                    assignedReadings.map((reading) => (
                      <a href={`/readings/${reading.id}`} key={reading.id} className="joined-readings-link">
                        <JoinedReadingListingCard 
                        reading={reading} 
                        />
                      </a>
                    ))
                  ) : (
                    <p>No joined readings yet.</p>
                  )}
                </div>
            </div>
          </div>
          <div className='directions-content'>
              <h2>How to do it?</h2>
              <div className='directions-content-box'>
                <br></br>
                To create a reading:
                <br></br>
                <li>Click on the top right menu button and select "Create Reading"</li>
                <li>Choose the desired content input type and format the document your way</li>
                <li>Click on "Create Reading" and share the code with your readers</li>

                <br></br>
                To join a reading:
                <li>Similar to creating a reading, go to top right corner</li>
                <li>Put in the code that you have been shared with and join the reading</li>
                <li>Once you have joined, you will be able to see the reading in the "To-Do Readings" section</li>
                <li>Click on the reading to start reading and annotating</li>
              </div>
           </div>
        </div>
    </div>
  )
}

export default Home